<template>
  <div class="asset-info">
    <div class="leftBack" @click="goBack">
      <van-icon name="arrow-left" />
    </div>

    <div class="asset-content">
      <h3 class="asset-list">扫描资产编码</h3>
      <div class="asset-name">
        <h4>{{ name }}</h4>
        <p>{{ code }}</p>
      </div>
      <h3 class="asset-list content">资产盘点列表</h3>
      <van-tabs
        v-model:active="current"
        animated
        color="rgb(26 121 160)"
        @click-tab="assetToggle"
      >
        <van-tab title="未盘点">
          <div
            class="assets-main todo"
            v-for="item in listInfo"
            :key="item.id"
            @click="handleAssetListA(item, 1)"
          >
            <p>
              名称：<span>{{ item["propertyBasicInfo.name"] }}</span>
            </p>
            <p>
              编码：<span>{{ item["propertyBasicInfo.code"] }}</span>
            </p>
            <p>
              类型：<span>{{
                item["propertyBasicInfo.belongTypeDic.name"]
              }}</span>
            </p>
            <p>
              规格型号：<span>{{
                item["propertyBasicInfo.specificationInfo"]
              }}</span>
            </p>
            <p>
              车牌号：<span>{{ item["propertyBasicInfo.principal"] }}</span>
            </p>
          </div>
          <van-divider v-if="!listInfo.length" class="empty-placeholder"
            >暂无未盘点数据</van-divider
          >
        </van-tab>
        <van-tab title="已盘点"
          ><div
            class="assets-main done"
            v-for="item in listInfo"
            :key="item.id"
            @click="handleAssetListA(item, 2)"
          >
            <p>资产名称：{{ item["propertyBasicInfo.name"] }}</p>
            <p>资产编码：{{ item["propertyBasicInfo.code"] }}</p>
            <p>资产类型：{{ item["propertyBasicInfo.belongTypeDic.name"] }}</p>
            <p>规格型号：{{ item["propertyBasicInfo.specificationInfo"] }}</p>
            <p>车牌号：{{ item["propertyBasicInfo.principal"] }}</p>
          </div>
          <van-divider v-if="!listInfo.length" class="empty-placeholder"
            >暂无已盘点数据</van-divider
          ></van-tab
        >
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReceiveCheck",

  data() {
    return {
      current: 0,
      id: "",
      listInfo: [],
      name: "",
      code: "",
      TODO: "CMS_CHECK_STATUS_TO_CHECK", //未盘点
      DONE: "CMS_CHECK_STATUS_HAD_CHECK", //已盘点
    };
  },
  created() {
    // this.name = this.$route.query.name;
    // this.code = this.$route.query.code;
    // this.current = 0
    // this.getlistInfo(this.TODO);
  },
  activated() {
    this.current = 0;
    this.name = this.$route.query.name;
    this.code = this.$route.query.code;
    this.assetToggle();
  },
  methods: {
    getlistInfo(type) {
      console.log(type);
      this.id = this.$route.query.id;
      let that = this;
      let params = {
        "checkOrder.id": this.id,
        "statusDic.code": type,
      };
      var option1 = {
        portName: "cms.property.zlbasicInfo.getList",
        data: params,
        successCallback(data) {
          that.listInfo = data.entities;
        },
      };

      this.$sapi.callPort(option1);
    },
    handleAssetListA(item, index) {
      this.$router.push({
        path: "receiveCheckInfo",
        query: {
          item: JSON.stringify(item),
          type: index,
        },
      });
    },
    assetToggle() {
      console.log(this.current);
      this.getlistInfo(this.current < 1 ? this.TODO : this.DONE);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.empty-placeholder {
  margin-top: 10rem;
}
.asset-content {
  padding: 1rem;
}
.asset-info {
  width: 100vw;
  height: calc(100% - 88px);
  background: #ffffff;
  position: relative;
}

.leftBack {
  position: fixed;
  top: 11px;
  left: 10px;
  z-index: 99999;
}

.asset-name {
  color: #333;
  margin-left: 8px;
  border-left: 1px solid #ccc;
  padding-left: 15px;
}
.asset-name h4 {
  font-weight: 300;
  font-size: 3rem;
  margin: 0;
}
.asset-name p {
  font-size: 1rem;
  margin: 0.5rem 0;
  word-wrap: break-word;
  word-break: normal;
}

.asset-name div {
  padding: 10px;
  border-bottom: 1px solid #666;
}

.asset-list {
  color: #666;
  margin: 15px 0;
  font-size: 18px;
  font-weight: 100;
  padding: 0 5px;
  border-left: 5px solid rgb(26 121 160);
  border-radius: 2px;
}
.asset-list.content {
  margin-top: 3rem;
}

.active {
  background-color: rgb(26 121 160);
  color: #ffffff;
}

.asset-list-A {
  margin-top: 20px;
  box-sizing: border-box;
  padding: 20px 10px 0;
}

.asset-list-A div {
  padding: 5px 0;
  color: #666;
}

.assets-main {
  color: #333;
  margin-left: 8px;
  border-left: 2px solid #ccc;
  padding-left: 15px;
  margin-top: 15px;
  background-color: #f6f6f6;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.assets-main.todo {
  border-color: #e15249;
}
.assets-main.done {
  border-color: #2db133;
}
.assets-main p {
  margin: 0;
  padding: 10px 0;
  font-size: 14px;
  word-wrap: break-word;
  word-break: normal;
}
.assets-main p span {
  font-weight: 600;
}
</style>
